import React, {useContext, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'

import {getAllProducts} from "../helpers/productFunctions";
import convertToURL from "../helpers/convertToURL";
import settings from "../admin/helpers/settings";

import Loader from "react-loader-spinner";
import {getAllCategories} from "../helpers/categoryFunctions";
import HomePageSection from "./HomePageSection";
import CategoriesMenu from "./CategoriesMenu";
import {LangContext} from "../App";
import axios from "axios";

const FullOffer = () => {
    const [dataPl, setDataPl] = useState({});
    const [dataEn, setDataEn] = useState({});
    const [data, setData] = useState({});
    const [products, setProducts] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [productsMode, setProductsMode] = useState(-1);

    const { content, langIndex, deliveryRegion } = useContext(LangContext);

    useEffect(() => {
        axios.get(`${settings.API_URL}/slider/get`)
            .then((res) => {
                const r = res?.data?.result;
                if(r) {
                    setDataPl(r[r.findIndex((item) => (item.language === 'pl'))]);
                    setDataEn(r[r.findIndex((item) => (item.language === 'en'))]);
                }
            });
    }, []);

    useEffect(() => {
        if(langIndex === 0) {
            setData(dataPl);
        }
        else {
            setData(dataEn);
        }
    }, [langIndex, dataPl, dataEn]);

    useEffect(() => {
        if(deliveryRegion !== -1) {
            /* Check number of categories available */
            getAllCategories()
                .then(res => {
                    if(res.data) {
                        const cats = res.data.result;
                        setCategories(res.data.result);
                        const availableCats = cats?.filter(item => {
                            return !item.hidden;
                        });
                        if(availableCats) {
                            if(availableCats.length > 1) {
                                /* Show categories */
                                setProductsMode(0);
                            }
                            else {
                                /* Show products */
                                setProductsMode(1);
                            }
                        }
                    }
                });

            const sortByPriority = (a, b) => {
                if(a.priority < b.priority) return 1;
                else return -1;
            }

            getAllProducts()
                .then(res => {
                    if(res?.data?.result) {
                        setProducts(res.data.result.sort(sortByPriority)?.filter((item) => {
                            return (deliveryRegion === 1 && item.poland) || (deliveryRegion === 0 && item.warsaw);
                        }));
                        setLoaded(true);
                    }
                });
        }
    }, [deliveryRegion]);

    const printPrice = (lMeat, mMeat, lVege, mVege, vegeAvailable, meatAvailable, lAvailable, mAvailable) => {
        if(lMeat && mMeat) {
            if(lMeat !== mMeat) {
                if(deliveryRegion === 0) {
                    if(lAvailable && mAvailable) {
                        return `${lMeat} zł / ${mMeat} zł`;
                    }
                    else if(lAvailable) {
                        return `${lMeat} zł`;
                    }
                    else {
                        return `${mMeat} zł`;
                    }
                }
                else {
                    return `${lMeat} zł`;
                }
            }
            else {
                return `${lMeat} zł`;
            }
        }
        else if(lVege && mVege) {
            if(lVege !== mVege) {
                if(deliveryRegion === 0) {
                    if(lAvailable && mAvailable) {
                        return `${lVege} zł / ${mVege} zł`;
                    }
                    else if(lAvailable) {
                        return `${lVege} zł`;
                    }
                    else {
                        return `${mVege} zł`;
                    }
                }
                else {
                    return `${lVege} zł`;
                }
            }
            else {
                return `${lVege} zł`;
            }
        }
        else if(lMeat && lVege) {
            if(lMeat !== lVege) {
                if(meatAvailable && vegeAvailable) {
                    return `${lMeat} zł / ${lVege} zł`;
                }
                else if(meatAvailable) {
                    return `${lMeat} zł`;
                }
                else {
                    return `${lVege} zł`;
                }
            }
            else {
                return `${lMeat} zł`;
            }
        }
        else if(mMeat && mVege && deliveryRegion === 0) {
            if(mMeat !== mVege) {
                if(meatAvailable && vegeAvailable) {
                    return `${mMeat} zł / ${mVege} zł`;
                }
                else if(meatAvailable) {
                    return `${mMeat} zł`;
                }
                else {
                    return `${mVege} zł`;
                }
            }
            else {
                return `${mMeat} zł`;
            }
        }
        else if(lMeat) {
            return `${lMeat} zł`;
        }
        else if(mMeat && deliveryRegion === 0) {
            return `${mMeat} zł`;
        }
        else if(lVege) {
            return `${lVege} zł`;
        }
        else if(mVege && deliveryRegion === 0) {
            return `${mVege} zł`;
        }
        else {
            return '';
        }
    }

    return <main className="offerContent offerContent--offer">
        {productsMode !== 0 ? (loaded ? <section className="offerContent__grid offerContent__grid--fullOffer">
                {products.map((item, index) => {
                    if(!item.hidden) {
                        return <Link className="offerContent__item"
                                     key={index}
                                     to={{
                                         pathname: `/produkt/${convertToURL(item.product_name.split("/")[0])}`,
                                         state: {
                                             id: item.id,
                                             title: item.name,
                                             price: item.price_m_meat
                                         }
                                     }}
                        >
                            <div className="offerContent__item__border">
                                <section className="offerContent__imgWrapper">
                                    <img className="offerContent__item__img"
                                         src={/*settings.API_URL + */"https://brunchbox.pl/image?url=/media/" + item.image} alt="produkt"/>
                                </section>
                                <h3 className="offerContent__item__header">
                                    {item.product_name.split("/")[langIndex]}
                                    <span className="offerContent__item__header--cursive">
                                        {item.bracket_name.split("/")[langIndex]}
                                    </span>
                                </h3>
                                <p className="offerContent__item__price">
                                    {printPrice(item.price_l_meat, item.price_m_meat, item.price_l_vege, item.price_m_vege, item.vege, item.meat, item.l, item.m)}
                                </p>
                            </div>
                        </Link>
                    }
                    else return "";
                })}
            </section> : <main className="loading">
                <Loader
                    type="puff"
                    color="#000"
                    width={100}
                    height={100}
                />
            </main>) : <CategoriesMenu />}
    </main>
}

export default FullOffer;
