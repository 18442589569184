import React, {useEffect, useState} from 'react';
import logo from '../static/img/brunch-box-logo.png';
import backgroundMobile from '../static/img/mobile.webp';
import backgroundDesktop from '../static/img/desktop.webp';
import TopMenu from "./TopMenu";
import InputMask from 'react-input-mask';
import {checkZipCode} from "../admin/helpers/deliveryFunctions";

const StartPage = () => {
    const [region, setRegion] = useState(-1);
    const [delivery, setDelivery] = useState(-1);
    const [code, setCode] = useState('');
    const [mapVisible, setMapVisible] = useState(false);

    useEffect(() => {
        const currentDate = new Date();

        // Expire after 1h
        if(region === 1) {
            localStorage.setItem('delivery', JSON.stringify({
                value: 'poland',
                expire: new Date(currentDate.getTime() + (60 * 60 * 1000))
            }));
        }
        else if(region === 0) {
            localStorage.setItem('delivery', JSON.stringify({
                value: 'warsaw',
                expire: new Date(currentDate.getTime() + (60 * 60 * 1000))
            }));

            window.location.href = '/';
        }
        else {
            localStorage.removeItem('delivery');
        }
    }, [region]);

    useEffect(() => {
        if(code.length === 6) {
            checkZipCode(code)
                .then((res) => {
                    if(res?.data?.result?.length) {
                        setDelivery(1);
                    }
                    else {
                        setDelivery(0);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setDelivery(1);
                });
        }
    }, [code]);

    return <div className={'startPage'}>
        <TopMenu />

        {mapVisible && <div className={'startPage__map'}>
            <button className={'startPage__map__close'}
                    onClick={() => { setMapVisible(false); }}>
                &times;
            </button>

            <iframe src="https://www.google.com/maps/d/embed?mid=1miSDE9Bc5G72TotyLe8T_MeQsIpg6gk&ehbc=2E312F"
                    width="940"
                    height="580"></iframe>
        </div>}

        <img className={'startPage__img startPage__img--desktop'} width={2048} height={1463} src={backgroundDesktop} alt={'bg'} />
        <img className={'startPage__img startPage__img--mobile'} width={732} height={1024} src={backgroundMobile} alt={'bg'} />

        <div className={'startPage__inner'}>
            <img className={'startPage__inner__logo'} src={logo} alt={'logo'} />

            <h3 className={'startPage__header'}>
                {region !== 1 ? 'Dokąd chcesz zamówić?' : 'Wpisz swój kod pocztowy i sprawdź dostępność naszej dostawy'}
            </h3>

            {region !== 1 && <div className={'startPage__buttons'}>
                <button className={region === 0 ? "slider__item__btn slider__item__btn--selected" : "slider__item__btn"}
                        onClick={() => { setRegion(0); }}>
                    Warszawa
                </button>
                <button className={region === 1 ? "slider__item__btn slider__item__btn--selected" : "slider__item__btn"}
                        onClick={() => { setRegion(1); }}>
                    Cała Polska
                </button>
            </div>}

            {region === 1 && <>
                <label>
                    <InputMask mask="99-999"
                               className={'input--masked'}
                               type={'text'}
                               value={code}
                               onChange={(e) => { setCode(e.target.value); }}
                               maskChar={null} />
                </label>

                <div className={'zipCodeResponse'}>
                    {delivery !== -1 && (delivery === 1 ? <span className={'text-green'}>
                    Dowozimy pod wskazany kod pocztowy! Możesz złożyć swoje zamówienie!

                    <a href={'/'}
                       className={'btn--showMap'}>
                        Złóż zamówienie
                    </a>
                </span> : <span className={'text-red'}>
                    Niestety, nie prowadzimy dostaw na wskazany adres

                    <div className={'flex-gap'}>
                        <button className={'btn--showMap btn--showMap--small'} onClick={() => { setRegion(-1); setDelivery(-1); setCode(''); }}>
                            Powrót
                        </button>
                        <button className={'btn--showMap'} onClick={() => { setMapVisible(true); }}>
                            Pokaż mapę z miejscami, w które dowozimy
                        </button>
                    </div>
                </span>)}
                </div>
            </>}
        </div>
    </div>
};

export default StartPage;
